import React from "react";
import { Tooltip } from "@src/common/components";
import { StopColumn } from "@src/shipments/types";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { Stop, TaskStatus } from "@api/graphql/generated/generated-types";
import { MultipStopCell } from "./MultipStopCell";
import StopTimeStatusDisplay from "./StopTimeStatusDisplay";

export default function StopStatusCell({
  dispatchZone,
  eta,
  isLikelyLate,
  taskStatusList,
  arrivedAt,
  completedAt,
  completedPrefix,
  pickUpStops,
  dropOffStops,
  dispatchZoneLine2,
  noStatus = false,
}: {
  stopBadge: StopColumn;
  dispatchZone: string | undefined;
  eta: string | undefined;
  isLikelyLate: Boolean | undefined;
  taskStatusList: TaskStatus[];
  arrivedAt: string | undefined;
  completedAt: string | undefined;
  completedPrefix: string;
  pickUpStops?: Stop[] | undefined;
  dropOffStops?: Stop[] | undefined;
  dispatchZoneLine2: string | undefined;
  noStatus?: boolean;
}) {
  const hasStartedTask = taskStatusList.some(
    (taskStatus) => taskStatus === TaskStatus.Started
  );
  const hasCompletedTask = taskStatusList.some(
    (taskStatus) => taskStatus === TaskStatus.Completed
  );

  const hasMultipleStops = (stops?: Stop[]) =>
    stops?.length && stops?.length > 1;

  const ShowMultiStopCellOrStatus = () => {
    if (hasMultipleStops(pickUpStops) || hasMultipleStops(dropOffStops)) {
      return (
        <MultipStopCell pickUpStops={pickUpStops} dropOffStops={dropOffStops} />
      );
    } else {
      return (
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex flex-init text-xs">{dispatchZone}</div>
            <div>
              {hasStartedTask && !hasCompletedTask && !arrivedAt && (
                <Tooltip content="Driver is currently on the way to this stop.">
                  <ArrowRightCircleIcon className="h-4 w-4 text-green-400" />
                </Tooltip>
              )}
            </div>
          </div>
          {dispatchZoneLine2 && (
            <div className="flex-1 text-xs truncate font-normal">
              {dispatchZoneLine2}
            </div>
          )}
          <StopTimeStatusDisplay
            noStatus={noStatus}
            eta={eta}
            arrivedAt={arrivedAt}
            completedAt={completedAt}
            completedPrefix={completedPrefix}
            isLikelyLate={isLikelyLate}
          />
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 items-center">
        <div className="flex-1 font-bold text-sm truncate">
          <div className="flex gap-1 items-center">
            <ShowMultiStopCellOrStatus />
          </div>
        </div>
      </div>
    </div>
  );
}
