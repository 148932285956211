import {
  CustomField,
  CustomFieldCustomerSpecificity,
  EndCustomer,
  useGetCustomFieldsQuery,
} from "@api/graphql/generated/generated-types";
import { useAuth } from "@src/common/hooks/useAuth";
import { useErrorToast } from "@src/common/hooks/useErrorToast";
import { useMemo } from "react";

interface UseCustomFieldsParams {
  endCustomer?: EndCustomer;
}

/**
 * Hook to fetch custom fields from the server
 * @param params - Optional parameters for the hook
 * @param params.endCustomer - Optional EndCustomer object to fetch specific custom fields for
 * @returns Object containing custom fields and loading state
 */
export function useCustomFields(params?: UseCustomFieldsParams) {
  const { endCustomer } = params || {};
  const { courier, courierId } = useAuth();
  const showErrorToast = useErrorToast();
  // Memoize endCustomerId to prevent unnecessary query reruns
  const endCustomerId = useMemo(() => endCustomer?.id || "", [endCustomer?.id]);

  // Query to get custom fields
  const { data, loading, error } = useGetCustomFieldsQuery({
    variables: {
      courierId: courierId || "",
      endCustomerId,
      visibleToSelectedEndCustomerOnly: true,
    },
    skip: !courierId || !endCustomerId,
    onError: (error) => {
      showErrorToast(error);
    },
  });
  // Memoize the custom fields to prevent unnecessary rerenders
  const customFields = useMemo(() => {
    // First try to use data from the query
    if (data?.customFields?.length) {
      return data.customFields;
    }

    // Fallback to endCustomer's customFields
    if (endCustomer?.customFields?.length) {
      return endCustomer.customFields as CustomField[];
    }

    // Finally fallback to courier's customFields if endCustomer is not set
    if (!endCustomer) {
      return (
        courier?.courier?.customFields.filter(
          (field) =>
            field.customerSpecificityType ===
            CustomFieldCustomerSpecificity.Public
        ) || ([] as CustomField[])
      );
    }

    return [];
  }, [data, endCustomer?.customFields, courier?.courier?.customFields]);

  return {
    customFields,
    loading,
    error,
    refetch: data?.customFields ? undefined : undefined, // Only expose refetch when needed in the future
  };
}
