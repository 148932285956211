import { useFormContext } from "react-hook-form";
import {
  ServiceType,
  ServiceTypeDueTimeType,
  ShipmentStopType,
} from "@api/graphql/generated/generated-types";
import moment from "moment";
import { findLastIndex } from "lodash";
import "moment-business-days"; // Import moment-business-days to extend moment

type UseServiceTypeAutoFillProps = {
  timeZoneId?: string;
  setSelectedDuration: React.Dispatch<React.SetStateAction<string>>;
  memoizedShipmentDate: Date;
};

export const useServiceTypeAutoFill = ({
  timeZoneId,
  setSelectedDuration,
  memoizedShipmentDate,
}: UseServiceTypeAutoFillProps) => {
  const { setValue, watch } = useFormContext();
  const watchStops = watch("stops");

  // Function to calculate due time
  const calculateDueTime = (
    deliveryTimeInMinutes: number,
    pickUpStopReadyTime?: string | undefined
  ) => {
    if (pickUpStopReadyTime) {
      const pickUpStopReadyTimeInDate = new Date(pickUpStopReadyTime);
      pickUpStopReadyTimeInDate.setMinutes(
        pickUpStopReadyTimeInDate.getMinutes() + deliveryTimeInMinutes
      );
      return pickUpStopReadyTimeInDate;
    }
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + deliveryTimeInMinutes);
    return currentTime;
  };

  // Function to handle fixed due time type
  const handleFixedDueTimeType = (selectedServiceType: ServiceType) => {
    if (!timeZoneId) return;

    const dueTimeInBusinessDaysAfter =
      selectedServiceType.dueTimeInBusinessDaysAfter as number;
    const dueTimeSecondsSinceMidnight =
      selectedServiceType.dueTimeSecondsSinceMidnight;
    const deliveryRoutedDaysAfter = selectedServiceType.deliveryRoutedDaysAfter;

    const dueTimeInCourierTimeZone = moment
      .tz(timeZoneId)
      .startOf("day")
      .businessAdd(dueTimeInBusinessDaysAfter)
      .add(dueTimeSecondsSinceMidnight, "seconds");

    const stopIndex = findLastIndex(watchStops, (field: any) => {
      return field.type === ShipmentStopType.DropOff;
    });

    if (stopIndex >= 0) {
      setValue(
        `stops.${stopIndex}.timeWindow.close`,
        dueTimeInCourierTimeZone.unix().toString()
      );
    }

    const shipmentDate = memoizedShipmentDate;
    if (shipmentDate) {
      const shipmentDateMoment = moment(shipmentDate);
      const deliveryRoutedDayPlusShipmentDate = shipmentDateMoment.add(
        deliveryRoutedDaysAfter,
        "days"
      );
      setValue(
        `stops.${stopIndex}.stopDate`,
        deliveryRoutedDayPlusShipmentDate.toDate()
      );
    }

    if (dueTimeInBusinessDaysAfter > 0) {
      setSelectedDuration("hasStopDates");
      setValue(
        `stops.${stopIndex}.deliverByDate`,
        dueTimeInCourierTimeZone.toDate()
      );
    }

    if (dueTimeInBusinessDaysAfter === 0) {
      setSelectedDuration("noStopDates");
      setValue(`stops.${stopIndex}.deliverByDate`, null);
    }
  };

  // Function to handle relative to pickup due time type
  const handleRelativeToPickupDueTimeType = (
    selectedServiceType: ServiceType
  ) => {
    if (!selectedServiceType.deliveryTimeInMinutes) return;

    const pickUpStop = watchStops.find(
      (stop: any) => stop.type === ShipmentStopType.PickUp
    );
    const pickUpStopReadyTime = pickUpStop?.timeWindow?.open;
    // Convert pickUpStopReadyTime Unix timestamp to Date
    const pickUpStopReadyDate = pickUpStopReadyTime
      ? new Date(parseInt(pickUpStopReadyTime) * 1000)
      : undefined;
    const dueTime = calculateDueTime(
      selectedServiceType.deliveryTimeInMinutes,
      pickUpStopReadyDate?.toISOString()
    );
    const stopIndex = findLastIndex(watchStops, (field: any) => {
      return field.type === ShipmentStopType.DropOff;
    });

    if (stopIndex >= 0) {
      setValue(`stops.${stopIndex}.timeWindow.close`, moment(dueTime).unix());
      setSelectedDuration("noStopDates");
      setValue(`stops.${stopIndex}.deliverByDate`, null);
    }
  };

  return {
    calculateDueTime,
    handleFixedDueTimeType,
    handleRelativeToPickupDueTimeType,
  };
};
