/**
 * Checks if a shipment has proposed package details that need review
 * @param shipment The shipment to check
 * @returns boolean indicating if the shipment has proposed package details that differ from current details
 */
export const hasProposedPackageDetailsToReview = (shipment: any): boolean => {
  if (!shipment) return false;

  return (
    (shipment.proposedNumPackages != null &&
      shipment.proposedNumPackages !== shipment.numPackages) ||
    (shipment.proposedPackageWeightInPounds != null &&
      shipment.proposedPackageWeightInPounds !== shipment.packageWeightInPounds)
  );
};
