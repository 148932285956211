import React from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { Label } from "@src/common/components";
import AddressInput from "@src/orders/create/components/AddressInput";
import StopInputs from "./StopInputs";
import { FieldErrors, FieldValues } from "react-hook-form";
import {
  EndCustomer,
  Shipment,
  ShipmentStopType,
} from "@api/graphql/generated/generated-types";
import { MapPinIcon as MapPinIconSolid } from "@heroicons/react/24/solid";
import {
  camelCaseToHumanized,
  formatTitleCaseWithUnderscores,
} from "@src/common/lib/textUtils";

type Props = {
  errors: FieldErrors<FieldValues>;
  value: any;
  selectedCustomer: EndCustomer | undefined;
  index: number;
  key: number | string;
  getValues: any;
  setValue: any;
  clearErrors: any;
  setError: any;
  control: any;
  stopPathName?: string;
  isRecurring?: boolean;
  selectedDuration?: string;
  onStopDateChange?: () => void;
  selectedShipment: Shipment | undefined;
};

function StopForm({
  errors,
  value,
  selectedCustomer,
  index,
  key,
  getValues,
  setValue,
  clearErrors,
  setError,
  control,
  stopPathName = "stops",
  isRecurring = false,
  selectedDuration,
  onStopDateChange,
  selectedShipment,
}: Props) {
  const stopType: ShipmentStopType = getValues(
    `${stopPathName}[${index}].type`
  );

  const handleAddressChange = (
    address: google.maps.places.AutocompletePrediction | undefined,
    index: number = 0
  ) => {
    if (address) {
      setValue(`${stopPathName}[${index}].streetAddress`, address.description);
      setValue(`${stopPathName}[${index}].googlePlaceId`, address.place_id);
      clearErrors(`${stopPathName}[${index}].streetAddress`);
      clearErrors(`${stopPathName}[${index}].googlePlaceId`);
    } else {
      setValue(`${stopPathName}[${index}].streetAddress`, undefined);
      setValue(`${stopPathName}[${index}].googlePlaceId`, undefined);
      setError(`${stopPathName}[${index}].streetAddress`, {
        type: "manual",
        message: "Please enter a valid address",
      });
    }
  };
  return (
    <li key={key} className="mb-10 ml-6 grid gap-2">
      {stopType === ShipmentStopType.PickUp ? (
        <span className="absolute flex items-center justify-center w-8 h-8 bg-primary-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-primary-900">
          <MapPinIcon className="w-5 h-5 text-primary-500" />
        </span>
      ) : (
        <span className="absolute flex items-center justify-center w-8 h-8 bg-primary-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-primary-900">
          <MapPinIconSolid className="w-5 h-5 text-red-500" />
        </span>
      )}
      <div className="col-span-2">
        <Label className="block text-xs font-medium text-gray-700 truncate">
          {formatTitleCaseWithUnderscores(stopType)} address
          {errors?.[`${stopPathName}`]?.[index]?.googlePlaceId && (
            <span className=" text-red-500">
              {`Please enter a ${camelCaseToHumanized(stopType)} address`}
            </span>
          )}
        </Label>
        <React.Fragment key={index}>
          <AddressInput
            required={true}
            handleAddressChange={(address) =>
              handleAddressChange(address, index)
            }
            control={control}
            size="mini"
            name={`${stopPathName}[${index}].streetAddress`}
            value={value}
          />
        </React.Fragment>
      </div>

      <React.Fragment key={index}>
        <StopInputs
          stopIndex={index}
          selectedCustomer={selectedCustomer}
          stopPathName={stopPathName}
          isRecurring={isRecurring}
          selectedDuration={selectedDuration}
          onStopDateChange={onStopDateChange}
          selectedShipment={selectedShipment}
        />
      </React.Fragment>
    </li>
  );
}

export default StopForm;
