import { ExtendedStopCreateInput } from "../hooks/useNewOrder";
import { DispatcherOrderEntryAdditionalField, Stop } from "@api/graphql/generated/generated-types";

interface StopAddress {
  locationId?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  googlePlaceId?: string | null;
}

export const mapStopAddress = (stop: ExtendedStopCreateInput): StopAddress => {
  // If locationId exists, return only locationId and clear other address fields
  if (stop.locationId) {
    return {
      locationId: stop.locationId,
      streetAddress: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      googlePlaceId: undefined,
    };
  }

  // If no locationId, return address fields and clear locationId
  return {
    locationId: undefined,
    streetAddress: stop.streetAddress || undefined,
    city: stop.city || undefined,
    state: stop.state || undefined,
    country: stop.country || undefined,
    googlePlaceId: stop.googlePlaceId || undefined,
  };
};

export const isPickUpTimeRequired = (
  index: number,
  dispatcherOrderEntryAdditionalFields?: DispatcherOrderEntryAdditionalField[]
): boolean => {
  return (
    !!dispatcherOrderEntryAdditionalFields?.includes(
      DispatcherOrderEntryAdditionalField.ReadyTime
    ) && index === 0
  );
};

export function makeTimeOnDate(time: Date | undefined, date: Date | undefined) {
  if (!time || !date) return undefined;
  const millisecondsSinceMidnight = time.getTime() - time.setHours(0, 0, 0, 0);
  return new Date(millisecondsSinceMidnight + date.setHours(0, 0, 0, 0));
}
