import {
  Shipment,
  ShipmentStopType,
} from "@api/graphql/generated/generated-types";
import moment from "moment";
import { getFirstTaskSequence } from "./typeConvertors";
import { formatPhoneNumber } from "@src/common/lib/textUtils";

export function initializeFormValues(shipmentSelectedForAction: Shipment) {
  const customFieldValuesToReplace =
    shipmentSelectedForAction.order.customFieldValues.reduce(
      (acc, customField) => {
        acc[customField.field.id] = customField.valueDisplay;
        return acc;
      },
      {} as { [key: string]: string }
    );

  return {
    id: shipmentSelectedForAction.order.id,
    endCustomerReferenceNumber:
      shipmentSelectedForAction.order.endCustomerReferenceNumber,
    shipmentDate: moment(
      shipmentSelectedForAction.shipmentDate,
      "YYYY-MM-DD"
    ).toDate(),
    courierId: shipmentSelectedForAction.order.courierId,
    caller: shipmentSelectedForAction.order.caller,
    endCustomerId: shipmentSelectedForAction.order.endCustomer.id,
    packageSize: shipmentSelectedForAction.packageSize,
    numPackages: shipmentSelectedForAction.numPackages,
    type: shipmentSelectedForAction.type,
    packageWeightInPounds: shipmentSelectedForAction.packageWeightInPounds,
    serviceTypeId: shipmentSelectedForAction.order.serviceType?.id,
    stops: [...shipmentSelectedForAction.stops]
      .sort((a, b) => {
        const taskSequenceDifference =
          getFirstTaskSequence(a) - getFirstTaskSequence(b);
        if (a.type === ShipmentStopType.Return) return 1;
        if (b.type === ShipmentStopType.Return) return -1;
        if (taskSequenceDifference !== 0) return taskSequenceDifference;
        if (
          a.type === ShipmentStopType.PickUp &&
          b.type !== ShipmentStopType.PickUp
        )
          return -1;
        if (
          a.type !== ShipmentStopType.PickUp &&
          b.type === ShipmentStopType.PickUp
        )
          return 1;
        return 0;
      })
      .map((stop) => ({
        streetAddress: stop.streetAddress,
        companyName: stop.companyName,
        contactName: stop.contactName,
        googlePlaceId: stop.googlePlaceId,
        id: stop.id,
        instruction: stop.instruction,
        phone: stop.phone && formatPhoneNumber(stop.phone),
        suite: stop.suite,
        timeWindow: stop.timeWindow
          ? {
              open: stop.timeWindow.open,
              close: stop.timeWindow.close,
            }
          : undefined,
        type: stop.type,
        stopDate: stop.stopDate
          ? moment(stop.stopDate, "YYYY-MM-DD").toDate()
          : undefined,
        deliverByDate:
          stop.timeWindow?.close && stop.type !== ShipmentStopType.PickUp
            ? moment(Number(stop.timeWindow.close) * 1000).toDate()
            : undefined,
        locationId: stop.locationId,
      })),
    packageTypeId: shipmentSelectedForAction.packageType?.id || null,
    packageDescription: shipmentSelectedForAction.packageDescription,
    customFieldValuesToReplace,
  };
}
