import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { AuthContext } from "@src/auth/components/AuthProvider";
import {
  DispatcherOrderEntryAdditionalField,
  ShipmentStopType,
} from "@api/graphql/generated/generated-types";

/**
 * Custom hook for validating ready time requirements in the EditOrderForm
 * @returns An object containing the checkIfReadyTimeIsRequired function
 */
const useReadyTimeValidation = () => {
  const { courier } = useContext(AuthContext);
  const { getValues, setError, clearErrors, setFocus } = useFormContext();

  /**
   * Checks if the ready time is required and validates it
   * @returns boolean indicating if the form is eligible to submit
   */
  const checkIfReadyTimeIsRequired = (): boolean => {
    const dispatcherOrderEntryAdditionalFields: any = [];

    // Check if ready time is required in the courier settings
    const isReadyTimeRequired = dispatcherOrderEntryAdditionalFields.includes(
      DispatcherOrderEntryAdditionalField.ReadyTime
    );

    if (!isReadyTimeRequired) {
      return true; // Ready time is not required, form can be submitted
    }

    // Get all stops from the form
    const stops = getValues("stops");

    if (!stops || stops.length === 0) {
      return true; // No stops, no validation needed
    }

    // Find the first pickup stop
    const pickupStopIndex = stops.findIndex(
      (stop: any) => stop.type === ShipmentStopType.PickUp
    );

    if (pickupStopIndex === -1) {
      return true; // No pickup stop found, no validation needed
    }

    // Check if the pickup stop has a ready time
    const readyTime = getValues(`stops.${pickupStopIndex}.timeWindow.open`);

    if (!readyTime) {
      // Set error on the ready time field
      setError(`stops.${pickupStopIndex}.timeWindow.open`, {
        type: "manual",
        message: "Ready time is required for pickup stops",
      });
      setFocus(`stops.${pickupStopIndex}.timeWindow.open`);
      return false; // Form is not eligible to submit
    }

    // Clear any existing errors
    clearErrors(`stops.${pickupStopIndex}.timeWindow.open`);
    return true; // Form is eligible to submit
  };

  return {
    checkIfReadyTimeIsRequired,
  };
};

export default useReadyTimeValidation;
