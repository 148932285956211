/* eslint-disable react-hooks/exhaustive-deps */
import { BaseModal } from "@src/common/components";
import { SIZE, ROLE } from "baseui/modal";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import moment from "moment";
import { useShipmentSelectionContext } from "../../context/ShipmentSelectionContext";
import { createOrderEditSchema } from "../../utils/orderEditSchema";
import useEditOrderFormSubmit from "../../hooks/useEditOrderFormSubmit";
import EditOrderSlide from "./EditOrderSlide";
import { ShipmentStatus, Stop } from "@api/graphql/generated/generated-types";
import ChangeRouteSlide from "./ChangeRouteSlide";
import { findIfShipmentIsMultiDay } from "@src/common/lib/shipmentStateFinders";
import { OneOffOrderDefaultValues } from "@src/orders/create/utils/formInitialStates";
import { initializeFormValues } from "@src/shipments/utils/makeFormDefaultValues";
import { isServiceTypeRequired } from "@src/common/lib/serviceTypeUtils";
import { useAuth } from "@src/common/hooks/useAuth";

export enum EditOrderModalSlides {
  EDIT_ORDER = "EDIT_ORDER",
  CHANGE_ROUTE = "CHANGE_ROUTE",
}

type Props = {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
};

function EditOrderModal({ setIsModalOpen, isModalOpen, onConfirm }: Props) {
  // contexts
  const { reset, watch } = useFormContext();
  const { setShipmentSelectedForAction, shipmentSelectedForAction } =
    useShipmentSelectionContext();
  // states
  const [stopsToCreate, setStopsToCreate] = useState<Stop[]>([]);
  const [selectedDuration, setSelectedDuration] = useState("noStopDates");
  const [currentSlide, setCurrentSlide] = useState<EditOrderModalSlides>(
    EditOrderModalSlides.EDIT_ORDER
  );
  const [isDifferentStopDate, setIsDifferentStopDate] = useState(false);
  // hooks
  const { setStopIdsToDelete } = useEditOrderFormSubmit({
    onConfirm,
    reset,
    setCurrentSlide,
    isDifferentStopDate,
  });
  const [shouldChangeRoute, setShouldChangeRoute] = useState(false);
  // variables
  const watchStops = watch("stops");
  // effects
  useEffect(() => {
    onStopDateChange();
  }, [selectedDuration]);

  useEffect(() => {
    if (watchStops) {
      const stopsToCreate = watchStops.filter((stop: Stop) => !stop.id);
      setStopsToCreate(stopsToCreate);
    }
  }, [watchStops]);

  useEffect(() => {
    const isShipmentActive = !!(
      shipmentSelectedForAction?.driver &&
      (shipmentSelectedForAction?.status === ShipmentStatus.Assigned ||
        shipmentSelectedForAction?.status === ShipmentStatus.InTransit ||
        shipmentSelectedForAction?.status === ShipmentStatus.Routed)
    );

    const shouldUpdateRoute =
      isShipmentActive &&
      (isDifferentStopDate || (stopsToCreate && stopsToCreate.length > 0));

    setShouldChangeRoute(!!shouldUpdateRoute);
  }, [stopsToCreate, shipmentSelectedForAction, isDifferentStopDate]);

  useEffect(() => {
    if (shipmentSelectedForAction) {
      const initialFormValues = initializeFormValues(shipmentSelectedForAction);
      const isMultiShipment = findIfShipmentIsMultiDay(
        shipmentSelectedForAction.stops,
        shipmentSelectedForAction.shipmentDate
      );
      reset(initialFormValues);
      isMultiShipment
        ? setSelectedDuration("hasStopDates")
        : setSelectedDuration("noStopDates");
    }
  }, [shipmentSelectedForAction]);

  const onStopDateChange = () => {
    if (
      selectedDuration === "hasStopDates" &&
      shipmentSelectedForAction &&
      watchStops?.length > 0
    ) {
      const stopDateChanged = watchStops.some((stop, index) => {
        const stopDate = moment(stop.stopDate).format("YYYY-MM-DD");
        const selectedShipmentStopDate =
          shipmentSelectedForAction.stops?.[index]?.stopDate;
        return stopDate !== selectedShipmentStopDate;
      });
      setIsDifferentStopDate(stopDateChanged);
    } else {
      setIsDifferentStopDate(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    reset();
    setShipmentSelectedForAction(undefined);
    setStopIdsToDelete([]);
    setIsDifferentStopDate(false);
    setCurrentSlide(EditOrderModalSlides.EDIT_ORDER);
    setShouldChangeRoute(false);
  };

  return (
    <BaseModal
      closeable
      onClose={handleClose}
      isOpen={isModalOpen}
      animate
      autoFocus
      size={SIZE.auto}
      role={ROLE.dialog}
      focusLock={false}
    >
      {currentSlide === EditOrderModalSlides.EDIT_ORDER && (
        <EditOrderSlide
          onConfirm={onConfirm}
          setIsModalOpen={setIsModalOpen}
          shouldChangeRoute={shouldChangeRoute}
          setCurrentSlide={setCurrentSlide}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
          onStopDateChange={onStopDateChange}
          isDifferentStopDate={isDifferentStopDate}
        />
      )}
      {currentSlide === EditOrderModalSlides.CHANGE_ROUTE && (
        <ChangeRouteSlide
          onCancel={handleClose}
          onConfirm={() => {
            reset();
            setStopIdsToDelete([]);
            setCurrentSlide(EditOrderModalSlides.EDIT_ORDER);
            onConfirm();
          }}
        />
      )}
    </BaseModal>
  );
}

function EditOrderModalWrapper(props: Props) {
  const { courier } = useAuth();
  const dispatcherOrderEntryAdditionalFields =
    courier?.courier?.dispatcherOrderEntryAdditionalFields || [];

  const serviceTypeRequired = isServiceTypeRequired(
    dispatcherOrderEntryAdditionalFields
  );

  const formMethods = useForm<any>({
    resolver: yupResolver(createOrderEditSchema(serviceTypeRequired)),
    defaultValues: OneOffOrderDefaultValues,
  });

  return (
    <div>
      <FormProvider {...formMethods}>
        <EditOrderModal {...props} />
      </FormProvider>
    </div>
  );
}

export default EditOrderModalWrapper;
