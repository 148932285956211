import {
  CreateLiveOrderMutation,
  CreateOneOffOrderMutationVariables,
  OrderAcquisitionType,
  Shipment,
  ShipmentStopType,
  useCreateLiveOrderMutation,
  useShipmentByIdLazyQuery,
} from "@api/graphql/generated/generated-types";
import { BaseModal, BaseModalHeader, BaseModalBody, Button, RadioWithLabel } from "@src/common/components";
import { useCourieStore } from "@src/common/lib/store";
import { getShipmentName } from "@src/schedules/utils/schedulesNameGenerators";
import CourieButton from "@src/common/components/Button";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { ApolloError, FetchResult, } from "@apollo/client";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useContext, useState } from "react";
import { makeTimeOnDate, mapStopAddress } from "@src/orders/create/utils/stopUtils";
import moment from "moment";
import { ExtendedStopCreateInput } from "@src/orders/create/hooks/useNewOrder";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";

interface DuplicateShipmentModalProps {
  shipment: Shipment | undefined;
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const DuplicateShipmentModal: React.FC<DuplicateShipmentModalProps> = ({
  shipment,
  isModalOpen,
  onClose,
  onSuccess,
}) => {
  const { showToast } = useCourieStore();
  const { isEmployee, customClaims, courieUser, courierId } = useContext(AuthContext);
  const [ getShipmentById ] = useShipmentByIdLazyQuery();
  const { setShipmentSelectedForAction } = useShipmentSelectionContext();
  const [duplicateType, setDuplicateType] = useState<string>("reattempt");

  const [
    createLiveOrderMutation,
    {
      loading: createLiveOrderMutationLoading,
    },
  ] = useCreateLiveOrderMutation();

  if (!shipment) {
    showToast({
      message: "Shipment not found",
      type: "error",
    });
    onClose();
    return null;
  }

  function getCreateVars(shipment: Shipment, deliveryType: string): any {
    const now = moment(); // New shipment should be for today

    const stops = shipment.stops.map((stop) => {
      const timeWindowOpen = stop.timeWindow.open
        ? new Date(parseInt(stop.timeWindow.open) * 1000)
        : undefined;
      const stopOpen = makeTimeOnDate(
        timeWindowOpen,
        now.toDate()
      );

      const timeWindowClose = stop.timeWindow.close
        ? new Date(parseInt(stop.timeWindow.close) * 1000)
        : undefined;
      const stopClose = makeTimeOnDate(
        timeWindowClose,
        now.toDate()
      );

      return {
        ...mapStopAddress(stop as ExtendedStopCreateInput),
        stopDate: now.format("YYYY-MM-DD"),
        stopTemplateId: undefined,
        companyName: stop.companyName || undefined,
        timeWindow: {
          open: stopOpen?.getTime()
            ? Math.round(stopOpen.getTime() / 1000)
            : undefined,
          close: stopClose?.getTime()
            ? Math.round(stopClose.getTime() / 1000)
            : undefined,
        },
        type: stop.type,
        suite: stop.suite,
        contactName: stop.contactName,
        phone: stop.phone,
        instruction: stop.instruction,
      };
    });

    var variables: CreateOneOffOrderMutationVariables = {
      courierId: shipment.courierId,
      orderTypeId: "2",
      endCustomerId: shipment.order.endCustomer.id || "",
      acquisitionType: OrderAcquisitionType.User,
      createdBy: isEmployee
        ? "EMPLOYEE"
        : courieUser?.id || customClaims?.courieUserId || undefined,
      originalOrderId: shipment.order.id,
      shipments: [{
        shipmentDate: now.format("YYYY-MM-DD"),
        stops: stops,
        packageWeightInPounds: shipment.packageWeightInPounds,
        numPackages: shipment.numPackages,
        packageSize: shipment.packageSize,
        packageTypeId: shipment.packageType?.id,
        packageDescription: `${deliveryType} of Order #${shipment.order.displayId}`,
        type: shipment.type,
      }],
    };

    if (shipment.order.serviceType?.id) {
      variables.serviceTypeId = shipment.order.serviceType.id;
    }

    if (shipment.order.costCenterId) {
      variables.costCenterId = shipment.order.costCenterId;
    }

    if (shipment.order.endCustomerReferenceNumber) {
      variables.endCustomerReferenceNumber = shipment.order.endCustomerReferenceNumber;
    }

    if (shipment.order.caller) {
      variables.caller = shipment.order.caller;
    }

    // Pricing adjustments deliberately not included

    const customFieldValues = shipment.order.customFieldValues.map(customField => ({
      fieldId: customField.fieldId,
      valueText: customField.valueText || ''
    }));
    if (customFieldValues.length > 0) {
      variables.customFieldValues = customFieldValues;
    }

    return variables;
  }

  function createOneOffOrder(
    data: CreateOneOffOrderMutationVariables,
    callback?: (error: any, result: any) => void
  ) {
    if (!Array.isArray(data.shipments)) return;

    createLiveOrderMutation({variables: data})
      .then((res) => {
        callback && callback(null, res);
        const trackingNumber =
          res.data?.createLiveOrder.shipment?.trackingNumber;
        const customerName = res.data?.createLiveOrder.endCustomer?.companyName;
        showToast(
          {
            message: "Order created successfully",
            type: "info",
            position: "bottom-right",
            secondaryText: `
              <div">
                <div>Customer: <span class="font-bold">${customerName}</span></div>
                <div>Tracking Number: <span class="font-bold">${trackingNumber}</span></div>
              </div>
            `,
            action: {
              label: "View Order",
              href: `/deliveries/orders?filters=${encodeURIComponent(
                JSON.stringify({ trackingNumber })
              )}`,
            },
          },
          10000
        );
      })
      .catch((e) => {
        callback && callback(e, null);
        showErrorToast(e, showToast);
      });
  }
  
  const orderCreationCallback = (error: ApolloError, response: FetchResult<CreateLiveOrderMutation>) => {
    if (error) {
      showErrorToast(error, showToast);
      return;
    }
    if (!response.data) {
      showToast({
        message: "Shipment not found",
        type: "error",
      });
      return;
    }
    // The shipment passed in is very sparse, so get the full data.
    getShipmentById({
      variables: {
        shipmentId: response.data?.createLiveOrder.shipment?.id || "",
        courierId: courierId || "",
      },
    }).then((res) => {
      onClose();
      setShipmentSelectedForAction(res.data?.shipmentById as Shipment);
      onSuccess();
    }).catch((err) => {
      showErrorToast(err, showToast);
      onClose();
    });
  }

  const createReattempt = () => { 
    const data = getCreateVars(shipment, "Reattempt");
    createOneOffOrder(data, orderCreationCallback);
  }

  const createReturn = () => {
    const baseData = getCreateVars(shipment, "Return");
    if (baseData.shipments[0].stops.length !== 2) {
      showToast({
        message: "Return must have 2 stops",
        type: "error",
      });
      return;
    }
    const data = {
      ...baseData,
      shipments: [
        {
          ...baseData.shipments[0],
          stops: [
            {
              ...baseData.shipments[0].stops[1],
              type: ShipmentStopType.PickUp,
            },
            {
              ...baseData.shipments[0].stops[0],
              type: ShipmentStopType.DropOff,
            },
          ],
        },
      ],
    };
    createOneOffOrder(data, orderCreationCallback);
  }

  const duplicateShipmentSubmit = () => {
    switch (duplicateType) {
      case "reattempt":
        createReattempt();
        break;
      case "return":
        createReturn();
        break;
      default:
        showToast({
          message: "Invalid duplicate type",
          type: "error",
        });
        break;
    }
  }

  return (<BaseModal isOpen={isModalOpen} onClose={onClose}>
      <BaseModalHeader>
        Duplicate {getShipmentName(shipment)}
      </BaseModalHeader>
      <BaseModalBody>
        <div>Choose a duplication method.</div>
        <div className="flex gap-3 pt-4">
          <RadioWithLabel
            id="reattempt"
            name="reattempt"
            value="reattempt"
            htmlFor="reattempt"
            checked={duplicateType === "reattempt"}
            onChange={() => setDuplicateType("reattempt")}
          >
            <span>Reattempt</span>
          </RadioWithLabel>
          <div className="text-sm text-gray-500 flex items-center">
            Creates an exact duplicate of the original order.
          </div>
        </div>
        <div className="flex gap-3 pt-4">
          <RadioWithLabel
            id="return"
            name="return"
            value="return"
            htmlFor="return"
            checked={duplicateType === "return"}
            onChange={() => setDuplicateType("return")}
          >
            <span>Return</span>
          </RadioWithLabel>
          <div className="text-sm text-gray-500 flex items-center">
            Creates an order where the original order&apos;s pickup and dropoff are swapped.
          </div>
        </div>

        <div className="flex justify-end gap-3 pt-4">
          <Button onClick={onClose} color="gray">
            Cancel
          </Button>
          <CourieButton
            type="button"
            data-cy="duplicate-shipment-button"
            isProcessing={createLiveOrderMutationLoading}
            onClick={duplicateShipmentSubmit}
          >
            Duplicate
          </CourieButton>
        </div>
      </BaseModalBody>
    </BaseModal>
  );
};

export default DuplicateShipmentModal;