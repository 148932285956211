import React from "react";
import { Tooltip } from "@src/common/components";

interface StopTimeStatusDisplayProps {
  noStatus?: boolean;
  eta?: string;
  arrivedAt?: string;
  completedAt?: string;
  completedPrefix?: string;
  isLikelyLate?: Boolean;
}

export default function StopTimeStatusDisplay({
  noStatus = false,
  eta,
  arrivedAt,
  completedAt,
  completedPrefix = "",
  isLikelyLate = false,
}: StopTimeStatusDisplayProps) {
  if (noStatus) return null;

  if (eta && !arrivedAt && !completedAt) {
    return (
      <div className="flex-1 font-normal">
        <div className="flex flex-row items-center gap-2">
          {isLikelyLate ? (
            <div className="flex flex-init">
              <Tooltip content="Likely late based on our estimate. This may change.">
                <div className="flex flex-init text-xs text-orange-400">
                  ETA {eta}
                </div>
              </Tooltip>
            </div>
          ) : (
            <div className="flex flex-init text-xs">ETA {eta}</div>
          )}
        </div>
      </div>
    );
  }

  if (arrivedAt && !completedAt) {
    return (
      <div className="flex-1 text-xs flex items-center gap-1 font-normal">
        <div>Arrived {arrivedAt}</div>
      </div>
    );
  }

  if (completedAt) {
    return (
      <div className="flex-1 text-xs flex items-center gap-1 text-green-500 truncate font-normal">
        <div>
          {completedPrefix} {completedAt}
        </div>
      </div>
    );
  }

  return null;
}
