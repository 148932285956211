import { useFormContext } from "react-hook-form";
import moment from "moment";
import { ShipmentStopType } from "@api/graphql/generated/generated-types";

/**
 * Custom hook to update ready time based on shipment date
 * Sets ready time to empty string if date is in the future, otherwise sets it to current time as timestamp
 */
export const useUpdateReadyTime = () => {
  const { setValue, getValues } = useFormContext();

  /**
   * Updates the ready time for pickup stops based on the shipment date
   * @param date - The shipment date
   */
  const updateReadyTime = (date: Date) => {
    const stops = getValues("stops");
    if (!stops || !Array.isArray(stops)) return;

    const now = moment();
    const shipmentDate = moment(date);
    const isDateInFuture = shipmentDate.isAfter(now, "day");

    stops.forEach((stop, index) => {
      // Only update pickup stops
      if (stop.type === ShipmentStopType.PickUp) {
        const readyTimePath = `stops.${index}.timeWindow.open`;

        if (isDateInFuture) {
          // If date is in the future, set ready time to empty string
          setValue(readyTimePath, "");
        } else {
          // If date is today or in the past, set ready time to current time as timestamp
          setValue(readyTimePath, now.unix());
        }
      }
    });
  };

  return { updateReadyTime };
};
